import invert from 'lodash/invert';
import EventsTags from '../enums/EventsTags';
import EventMappingConnectorsNames from '../enums/EventMappingConnectorsNames';
import { EventProperties } from './utils';

export const CONNECTORS_NAMES = {
  hubspot: 'hubspot',
  segment: 'segment',
  amplitude: 'amplitude',
  kissmetrics: 'kissmetrics',
  mixpanel: 'mixpanel',
  marketo: 'marketo',
  salesforce_campaigns: 'salesforce_campaigns',
  salesforce_tasks: 'salesforce_tasks',
  s3: 's3',
  snowflake: 'snowflake',
  bigquery: 'bigquery',
};

export const ANALYTICS_CONNECTORS = [
  'amplitude',
  'kissmetrics',
  'mixpanel',
  'segment',
  's3',
  'bigquery',
  'snowflake',
];

export const EVENTS_GROUPS_NAMES: { [key: string]: string } = {
  formSubmission: 'Hubspot Form Submission',
  emailActivity: 'Hubspot Email Activity',
  hubspot: 'Hubspot',
  analytic: 'Analytic',
  segment: 'Segment',
  amplitude: 'Amplitude',
  kissmetrics: 'Kissmetrics',
  mixpanel: 'Mixpanel',
  marketo: 'Marketo',
  salesforce_campaigns: 'Salesforce_campaigns',
  salesforce_tasks: 'Salesforce_tasks',
  s3: 'S3',
  bigquery: 'Bigquery',
  snowflake: 'Snowflake',
};

export const EVENT_GROUPS_TO_CONNECTORS = invert(EVENTS_GROUPS_NAMES);

export const CONNECTORS_TO_YAML: { [key: string]: string } = {
  hubspot: 'has_hubspot',
  segment: 'has_segment',
  amplitude: 'has_amplitude',
  kissmetrics: 'has_kissmetrics',
  mixpanel: 'has_mixpanel',
  marketo: 'has_marketo',
  salesforce_campaigns: 'has_salesforce_campaigns',
  salesforce_tasks: 'has_salesforce_tasks',
  s3: 'has_s3',
};

export const EVENTS_KEYS: { [key: string]: { [key: string]: string } } = {
  hubspot: {
    event: 'event',
    mkEventName: 'mkEventName',
    mkEventNameSignals: 'mkEventNameSignals',
    nonUserActivity: 'nonUserActivity',
    activityType: 'activityType',
  },
  analytic: {
    event: 'event',
    nonUserActivity: 'nonUserActivity',
    mkEventName: 'mkEventName',
    mkEventNameSignals: 'mkEventNameSignals',
    activityType: 'activityType',
    excludeFromMapping: 'excludeFromMapping',
  },
  marketo: {
    event: 'event',
    nonUserActivity: 'nonUserActivity',
    mkEventName: 'mkEventName',
    mkEventNameSignals: 'mkEventNameSignals',
    activityType: 'activityType',
  },
  salesforce: {
    event: 'event',
    nonUserActivity: 'nonUserActivity',
    mkEventName: 'mkEventName',
    mkEventNameSignals: 'mkEventNameSignals',
    activityType: 'activityType',
  },
};

export const ACTIVITY_TYPE = [
  'App Usage',
  'Email Activity',
  'Web Activity',
  'Sales Activity',
  'Marketing Activity',
  'Non-user Activity',
];

export const MARKETO_EVENTS = [
  'open_email',
  'click_email',
  'unsubscribe_email',
  'email_bounced_soft',
  'visit_webpage',
  'fill_out_form',
  'interesting_moment',
];

export const HUBSPOT_EVENTS: string[] = [
  'form_submission',
  'statuschange',
  'click',
];

export const SALESFORCE_CAMPAIGN_OBJECTS = ['Campaign', 'CampaignMember'];

export const SALESFORCE_TASKS_OBJECTS = ['Task'];
export const MARKETO_OBJECTS = ['activity'];
export const SEGMENT_OBJECTS = ['track'];
export const AMPLITUDE_OBJECTS = ['event'];
export const MIXPANEL_OBJECTS = ['event'];
export const KISSMETRICS_OBJECTS = ['event'];
export const S3_OBJECTS = ['event'];
export const BIGQUERY_OBJECTS = ['event'];
export const SNOWFLAKE_OBJECTS = ['event'];

export const CONNECTOR_OBJECT_PULL_CONFIG: { [key: string]: string[] } = {
  salesforce_campaigns: SALESFORCE_CAMPAIGN_OBJECTS,
  salesforce_tasks: SALESFORCE_TASKS_OBJECTS,
  hubspot: HUBSPOT_EVENTS,
  marketo: MARKETO_OBJECTS,
  segment: SEGMENT_OBJECTS,
  amplitude: AMPLITUDE_OBJECTS,
  mixpanel: MIXPANEL_OBJECTS,
  kissmetrics: KISSMETRICS_OBJECTS,
  s3: S3_OBJECTS,
  bigquery: BIGQUERY_OBJECTS,
  snowflake: SNOWFLAKE_OBJECTS,
};

export const DATA_DISCOVERY_EVENT_KEYS: { [key: string]: string } = {
  salesforce_campaigns: 'most_frequent_campaign_types',
  salesforce_tasks: 'task_types',
  marketo: 'event_type_distribution',
};

export const DEFAULT_EVENT_PROPERTIES: EventProperties = {
  email: '',
  isItOldEvents: false,
  publishedConnectorEvents: [],
  savedConnectorEvents: [],
  draft: false,
  disabledConnectors: [],
  history: [{ events: [], tag: EventsTags.Draft }],
  currentTag: EventsTags.Draft,
  madMlSqlQuery: '',
};

export const EVENT_MAPPING_CONNECTORS_VALUES: {
  [key in EventMappingConnectorsNames]: number;
} = {
  hubspot: 0,
  kissmetrics: 0,
  salesforce_tasks: 0,
  salesforce_campaigns: 0,
  marketo: 0,
  amplitude: 0,
  s3: 1,
  madMl: 0,
  segment: 0,
  mixpanel: 0,
  bigquery: 0,
  snowflake: 0,
};

export const [FORM_SUBMISSION, STATUSCHANGE, CLICK] = HUBSPOT_EVENTS;
export const HUBSPOT_EVENT_GROUP = 'Hubspot';
