import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import { useMutation } from '@tanstack/react-query';
import EventsTags from '../../../enums/EventsTags';
import SelectOptionDataModel from '../../models/SelectOptionDataModel';
import { EventMappingHistoryManager } from './hooks/useEventMappingHistory';
import { ScriptCheckerManager } from './hooks/useScriptChecker';
import { SelectOptionLike } from './utils';
import CircleBadge, { CircleBadgeColors } from './utils/CircleBadge';
import { EventMappingHistoryValueContainer } from './EventMappingHistoryValueContainer';
import { EventMappingHistoryOption } from './EventMappingHistoryOption';

type EventMappingHeaderComponentProps = {
  editable: boolean;
  historyOptions: SelectOptionLike[];
  editForm: (value: boolean) => void;
  copy: () => void;
  saveEvents: () => Promise<void>;
  publishEvents: () => Promise<void>;
  handleOnHistoryChange: (c: SelectOptionDataModel) => void;
  superKudu: boolean;
  hasEventErrors: boolean;
  scriptChecker: ScriptCheckerManager;
  eventHistory: EventMappingHistoryManager;
};

export function getBadgeColorFrom(label: string) {
  const { GRAY, GREEN, BLUE } = CircleBadgeColors;
  const { Draft, Live } = EventsTags;
  switch (label) {
    case Draft:
      return GRAY;
    case Live:
      return GREEN;
    default:
      return BLUE;
  }
}

export default function EventMappingHeader({
  editable,
  historyOptions,
  scriptChecker,
  publishEvents,
  handleOnHistoryChange,
  copy,
  saveEvents,
  editForm,
  superKudu,
  hasEventErrors,
  eventHistory,
}: EventMappingHeaderComponentProps) {
  const {
    selectedHistory,
    selectedHistoryId,
    currentTag,
    isDraft,
    resetCurrentEvents,
    hasUnsavedChanges,
    hasUnsavedMadMlChanges,
    resetMadMlQuery,
    isCustom,
    hasDraft,
  } = eventHistory;

  // For row text inputs, we only commit changes on blur, however if clicking on 'Save' before blurring a text input,
  // blur events won't happen before the save, so we use this to indirectly trigger these.
  const [, blurEverything] = useState(false);

  const updatedAtFormatted = moment(selectedHistory?.updatedAt ?? 0).format(
    'lll'
  );
  const selectedOption = historyOptions.find(
    (el) => el.value === selectedHistoryId
  );
  const lastPublisher =
    selectedHistory?.lastPublisher ?? selectedHistory?.email ?? 'unknown';
  const [isSaved, setSaved] = useState(false);

  const { isLoading: isSavingDraft, mutate: save } = useMutation(async () => {
    setSaved(false);
    await saveEvents();
    setSaved(true);
  });

  useEffect(() => {
    // Resets flow (save before publish) from the start if enabling/disabling edit mode.
    if (editable) {
      setSaved(false);
    }
  }, [editable]);

  const hasOverallUnsavedChanges = hasUnsavedChanges || hasUnsavedMadMlChanges;

  return (
    <div className="row d-flex navbar navbar-expand-lg navbar-light bg-light mb-4 ml-0 mr-0 border-top box-shadow-3d">
      <ReactTooltip
        place="top"
        getContent={(tip) => <div style={{ maxWidth: '300px' }}>{tip}</div>}
      />
      <div className="col-4 justify-content-start">
        <div className="font-italic text-muted">
          {isDraft && (
            <div className="d-flex flex-row align-items-center gap-2">
              <div>
                <CircleBadge variant={CircleBadgeColors.GRAY} />
              </div>
              <span>Draft version</span>
            </div>
          )}
          {!isDraft && (
            <>
              {currentTag === EventsTags.Live ? (
                <div className="d-flex flex-row align-items-center gap-2">
                  <div>
                    <CircleBadge variant={CircleBadgeColors.GREEN} />
                  </div>
                  <span>
                    {editable
                      ? `Draft based on a live version published by ${lastPublisher} at ${updatedAtFormatted}`
                      : `This configuration is Live, published by ${lastPublisher} at ${updatedAtFormatted}`}
                  </span>
                </div>
              ) : (
                <div className="d-flex flex-row align-items-center gap-2">
                  <div>
                    <CircleBadge variant={CircleBadgeColors.BLUE} />
                  </div>
                  <span>
                    {editable
                      ? `Draft based on a previous version published by ${lastPublisher} at ${updatedAtFormatted}`
                      : `This mapping is a previous version published by ${lastPublisher} at ${updatedAtFormatted}`}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="col-8">
        <div className="row d-flex flex-row justify-content-end">
          {!editable && (
            <div className="col-3">
              <Select
                options={historyOptions}
                value={selectedOption}
                className="ml-1"
                onChange={handleOnHistoryChange}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (baseStyles) => ({
                    ...baseStyles,
                    // Make sure the dropdown menu is above the table below.
                    zIndex: 1,
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    paddingLeft: 0,
                  }),
                }}
                components={{
                  Option: EventMappingHistoryOption,
                  ValueContainer: EventMappingHistoryValueContainer,
                }}
              />
            </div>
          )}
          {hasOverallUnsavedChanges && (
            <div className="col-auto">
              <div className="d-flex flex-row gap-3 justify-content-end">
                <span className="align-self-center text-danger">
                  You have unsaved changes
                </span>
                <Button
                  bsStyle="danger"
                  onClick={isCustom ? resetMadMlQuery : resetCurrentEvents}
                  className="align-self-center"
                >
                  Undo
                </Button>
              </div>
            </div>
          )}
          <div className="col-auto my-auto">
            {!isDraft && !editable && (
              <Button
                bsStyle="primary"
                className="ml-1"
                onClick={async () => {
                  const res = await Swal.fire({
                    title: `Start draft from ${
                      currentTag === EventsTags.Live
                        ? 'live'
                        : 'previously published'
                    } version?`,
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Start Draft',
                    text: hasDraft
                      ? 'A draft currently exists. Would you like to replace this draft? This action cannot be undone. Click cancel to go back, or click Start draft to start a new draft from the live version.'
                      : 'This will create a new draft based on the current version. You will be able to make changes and publish them later.',
                  });
                  if (res.isConfirmed) {
                    editForm(true);
                  }
                }}
              >
                <span>Start draft</span>
              </Button>
            )}
            {superKudu && editable && (
              <Button
                className="btn btn-light btn-outline-dark ml-1"
                onClick={copy}
              >
                <i className="far fa-copy"></i>
                <span className="ml-1">Copy</span>
              </Button>
            )}
            {isDraft && !editable && (
              <Button
                bsStyle="primary"
                className="ml-1"
                onClick={() => editForm(true)}
              >
                <span>Edit draft</span>
              </Button>
            )}
            {editable && (
              <Button
                className="btn btn-light btn-outline-dark ml-1"
                data-tip="Cancel draft changes and return to read-only mode."
                onClick={() => {
                  resetCurrentEvents();
                  editForm(false);
                }}
              >
                <i className="fas fa-undo" />
                <span className="ml-1">Exit Draft</span>
              </Button>
            )}
            {editable && (
              <>
                <Button
                  bsStyle="primary"
                  className="ml-1"
                  onClick={() => {
                    blurEverything((v) => !v);
                    save();
                  }}
                  disabled={
                    hasEventErrors || (isDraft && !hasOverallUnsavedChanges)
                  }
                >
                  {isSavingDraft ? (
                    <span>Saving draft..</span>
                  ) : (
                    <>
                      {isSaved && !hasOverallUnsavedChanges ? (
                        <>
                          <i className="fas fa-check" />
                          <span className="ml-1">Saved</span>
                        </>
                      ) : (
                        <>
                          <i
                            className={
                              isDraft && !hasOverallUnsavedChanges
                                ? 'fas fa-check'
                                : 'fas fa-save'
                            }
                          />
                          <span className="ml-1">
                            {isDraft && !hasOverallUnsavedChanges
                              ? 'Up-to-date'
                              : 'Save Draft'}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </Button>
                {!eventHistory.isCustom && (
                  <span
                    data-tip={
                      (!isDraft && !isSaved) ||
                      (isDraft && hasOverallUnsavedChanges)
                        ? 'You must save this mapping before publishing it.'
                        : 'Publish a new live configuration.'
                    }
                  >
                    <Button
                      bsStyle="primary"
                      className="ml-1"
                      onClick={() => {
                        blurEverything((v) => !v);
                        publishEvents();
                      }}
                      disabled={
                        (!isDraft && !isSaved) ||
                        (isDraft && hasOverallUnsavedChanges) ||
                        isSavingDraft ||
                        scriptChecker.isScriptCheckerActive ||
                        hasEventErrors
                      }
                    >
                      <i className="fas fa-upload"></i>
                      <span className="ml-1">Publish changes</span>
                    </Button>
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
