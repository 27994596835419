import React, { useEffect, useState } from 'react';
import model_integration from '../../../models/integration';
import GongConnectButton from './GongConnectButton';

export type TabValues = 'overview' | 'configuration';

type GongParentComponentProps = {
  tenant: number;
};

export default function GongParentComponent({
  tenant,
}: GongParentComponentProps) {
  const integration = 'gong';

  const [loading, setLoading] = useState<boolean>(true);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const fetchGongCredentials = () => {
    const model = new model_integration.Credentials_model({
      tenant,
      integration,
    });

    model.fetch({
      success: (_collection: any, response: any) => {
        setLoading(false);
        setIsConnected(Object.keys(response).length > 0);
      },
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          model.set({
            not_found: true,
          });
        }
        setLoading(false);
        setIsConnected(false);
      },
    });
  };

  useEffect(() => {
    fetchGongCredentials();
  }, []);

  return (
    <div className="row ml-4">
      <div className="tab-content w-100" id="TabContent">
        <div>
          <div className="row justify-content-center">
            <div className="col-8 mt-4 mb-4">
              <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src={`/media/integrations/logos/gong-simple.svg`}
                    />
                    <h5 className="mb-0 ml-3">Gong</h5>
                  </div>
                </div>

                <div className="card-body">
                  <div className="d-flex">
                    <div className="row w-75 mr-4">
                      <div className="col">
                        <div>
                          <h5>Overview</h5>
                          <p>
                            MadKudu turns Gong into a one stop shop for
                            prospecting. Sellers no longer need to switch
                            between multiple tools to send relevant outreach to
                            the right prospects. MadKudu provides account and
                            contact level intelligence directly in Gong’s
                            platform to arm sellers with the context they need
                            to prospect effectively.
                          </p>
                        </div>
                        <div className="mt-3">
                          <h5>How MadKudu x Gong works</h5>
                          <div>
                            <h6>Pre-requisite</h6>
                            <ul className="pl-3">
                              <li>
                                You must be a Tech Admin in Gong to connect Gong
                                to MadKudu
                              </li>
                              <li>
                                Your Gong users must have a seat for Engage in
                                Gong to view MadKudu Account Intelligence in
                                Gong Engage and use the “Add to Gong Flow”.
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="mt-3">
                          <h5>
                            Once Gong connected, your users will be able to
                          </h5>
                          <div>
                            <ul className="pl-3">
                              <li>
                                View MadKudu Account Intelligence in Gong
                                Engage’s Accounts
                              </li>
                              <li>
                                Add prospects to Gong Flow from MadKudu
                                playbooks and sales intelligence
                              </li>
                              <li>
                                Use MadKudu Chrome Extension on any Gong page
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-25">
                      <p>
                        <b>Status</b>
                        <span className="d-block pt-1">
                          <GongConnectButton
                            tenant={tenant}
                            integration={integration}
                            loading={loading}
                            isConnected={isConnected}
                          />
                        </span>
                      </p>
                      <p className="mt-4">
                        <b>Links</b>
                      </p>
                      <p>
                        <a
                          href="https://www.gong.io/ai-powered-sales-engagement/"
                          target="_blank"
                        >
                          Get a Gong Engage demo
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://help.madkudu.com/docs/gong-engage"
                          target="_blank"
                        >
                          How to use MadKudu x Gong
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://help.gong.io/hc/en-us/articles/16937747340429-Set-up-Engage-for-reps"
                          target="_blank"
                        >
                          How to setup Gong Engage
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://help.gong.io/hc/en-us/categories/360003308232-Configuring-Gong"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Gong documentation
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
