export type TableMapping = {
  event: string;
  contact_update: string;
  account_update: string;
};

export type MAPPABLE_TABLE = keyof TableMapping;

export const MAPPABLE_TABLES = [
  'event',
  'contact_update',
  'account_update',
] as const;

const EVENT_COLUMN_NAMES = [
  'id',
  'event_name',
  'event_timestamp',
  'contact_id',
  'created_at',
  'updated_at',
] as const;

const CONTACT_COLUMN_NAMES = [
  'id',
  'email',
  'created_at',
  'updated_at',
  'account_id',
] as const;

const ACCOUNT_COLUMN_NAMES = [
  'id',
  'domain',
  'created_at',
  'updated_at',
  'salesforce_account_id',
] as const;

type EventColumns = typeof EVENT_COLUMN_NAMES[number];
type ContactColumns = typeof CONTACT_COLUMN_NAMES[number];
type AccountColumns = typeof ACCOUNT_COLUMN_NAMES[number];

export type Columns<T> = T extends 'event'
  ? EventColumns
  : T extends 'contact_update'
  ? ContactColumns
  : T extends 'account_update'
  ? AccountColumns
  : never;

export type ColumnNames = {
  event?: Partial<Record<EventColumns, string>>;
  contact_update?: Partial<Record<ContactColumns, string>>;
  account_update?: Partial<Record<AccountColumns, string>>;
};

export const REQUIRED_COLUMNS: Record<
  MAPPABLE_TABLE,
  Columns<MAPPABLE_TABLE>[]
> = {
  event: [
    'id',
    'event_name',
    'event_timestamp',
    'contact_id',
    'created_at',
    'updated_at',
  ],
  contact_update: ['id', 'email', 'created_at', 'updated_at'],
  account_update: ['id', 'domain', 'created_at', 'updated_at'],
};

export const REQUIRED_COLUMN_VALUES = Object.keys(REQUIRED_COLUMNS).map(
  (table: MAPPABLE_TABLE) =>
    Object.keys(REQUIRED_COLUMNS[table]).map(
      (column: Columns<MAPPABLE_TABLE>) => ({
        column,
        value: '',
      })
    )
) as ColumnNames;

export const OPTIONAL_COLUMNS: Record<
  MAPPABLE_TABLE,
  Columns<MAPPABLE_TABLE>[]
> = {
  event: [],
  contact_update: ['account_id'],
  account_update: ['salesforce_account_id'],
};

export function getTableLabel(
  table: 'event' | 'contact_update' | 'account_update'
): 'Events' | 'Contacts' | 'Accounts' {
  switch (table) {
    case 'event':
      return 'Events';
    case 'contact_update':
      return 'Contacts';
    case 'account_update':
      return 'Accounts';
    default:
      throw new Error(`Unknown table: ${table}`);
  }
}

export function getColumnsFromTable(table: MAPPABLE_TABLE) {
  return [
    ...(REQUIRED_COLUMNS?.[table] ?? []).map((column) => ({
      column,
      required: true,
    })),
    ...(OPTIONAL_COLUMNS?.[table] ?? []).map((column) => ({
      column,
      required: false,
    })),
  ];
}

export function getUsedColumnsInTable(
  table: MAPPABLE_TABLE,
  columnNames: ColumnNames
) {
  return Object.values(columnNames?.[table] ?? {});
}
