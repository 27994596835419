import axios from 'axios';
import getFormData from './models/formData';
import { convertToMkEventStyle } from './components/generic/utils';
import EventsTags from '../enums/EventsTags';
import SelectOptionDataModel from './models/SelectOptionDataModel';
import ActiveIntegrationDataModel from './models/ActiveIntegrationDataModel';
import EventMappingConnectorsNames from '../enums/EventMappingConnectorsNames';
import ConnectorsNames from '../enums/ConnectorsNames';
import RelevantEventsInformationModel from './models/RelevantEventsInformationModel';
import {
  ACTIVITY_TYPE,
  CONNECTORS_NAMES,
  CONNECTORS_TO_YAML,
  DEFAULT_EVENT_PROPERTIES,
  EVENT_MAPPING_CONNECTORS_VALUES,
  EVENTS_GROUPS_NAMES,
} from './constants';

export type EventProperties = {
  publishedConnectorEvents: any[];
  savedConnectorEvents: any[];
  isItOldEvents: boolean;
  draft: boolean;
  disabledConnectors: string[];
  history: any[];
  currentTag: EventsTags;
  madMlSqlQuery: string;
  email: string;
};

const LOWERCASE_CONNECTORS_NAMES = Object.keys(CONNECTORS_NAMES).map((key) =>
  key.toLowerCase()
);

function isHubspotEventGroup(eventGroup: string) {
  const lowercaseEventGroup = String(eventGroup).toLowerCase();
  return (
    lowercaseEventGroup === EVENTS_GROUPS_NAMES.emailActivity.toLowerCase() ||
    lowercaseEventGroup === EVENTS_GROUPS_NAMES.formSubmission.toLowerCase() ||
    lowercaseEventGroup === EVENTS_GROUPS_NAMES.hubspot.toLowerCase()
  );
}

function filterEventsByEventGroup(
  connector: string,
  events: UnknownArrayOfObjects
) {
  const lowercaseConnector = String(connector).toLowerCase();
  return events.filter((event: UnknownObject) => {
    // Special case for Hubspot which has multiple event groups
    if (lowercaseConnector === CONNECTORS_NAMES.hubspot.toLowerCase()) {
      return isHubspotEventGroup(event.eventGroup);
    }
    // All other connectors have only one event group, except Salesforce but it has two
    // separate event groups 'salesforce_campaigns' and 'salesforce_tasks'
    return LOWERCASE_CONNECTORS_NAMES.includes(lowercaseConnector);
  });
}

function filterEventsByConnector(
  connector: string,
  events: UnknownArrayOfObjects = []
) {
  return events.filter((event: UnknownObject) => {
    const { eventGroup } = event;
    if (
      connector === CONNECTORS_NAMES.hubspot &&
      (eventGroup === EVENTS_GROUPS_NAMES.formSubmission ||
        eventGroup === EVENTS_GROUPS_NAMES.emailActivity)
    ) {
      return true;
    }
    return (
      String(eventGroup).toLowerCase() ===
      String(EVENTS_GROUPS_NAMES[connector]).toLowerCase()
    );
  });
}

export function areEventsSame(
  savedEvents: UnknownArrayOfObjects,
  publishedEvents: UnknownArrayOfObjects
): boolean {
  return (
    JSON.stringify(savedEvents.sort()) ===
    JSON.stringify(publishedEvents.sort())
  );
}

export function areMadMlQueriesSame(
  savedMadMlQuery: string,
  publishedMadMlQuery: string
): boolean {
  return (
    savedMadMlQuery?.toLowerCase().trim() ===
    publishedMadMlQuery?.toLowerCase().trim()
  );
}

export async function getSaved(
  tenant: number,
  connector: string
): Promise<{
  events: UnknownArrayOfObjects;
  email: string;
  madMlSqlQuery: string;
}> {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/events/${connector}/saved`
    );
    const { events = [], email = '', madMlSqlQuery = '' } = data;
    return { events, email, madMlSqlQuery };
  } catch (_e) {
    return { events: [], email: null, madMlSqlQuery: '' };
  }
}

function getLiveEventsFromHistory(historyEvents: any[]) {
  return historyEvents.length > 0
    ? historyEvents.filter((historyEvent) => {
        return historyEvent.live;
      })[0]?.events
    : [];
}

function getLiveMadMlQueryFromHistory(madMlHistory: any[]) {
  return madMlHistory.filter((madMl) => {
    return madMl.live;
  })[0]?.madMlSqlQuery;
}

function setTagToHistoryEvent(history: UnknownObject, tag: EventsTags) {
  const newHistory = history;
  newHistory.tag = tag;
  return newHistory;
}

function setTagToHistoryEventsList(
  history: UnknownArrayOfObjects,
  tag: EventsTags
) {
  return history.map((historyObject) => {
    if (historyObject.live) {
      return setTagToHistoryEvent(historyObject, EventsTags.Live);
    }
    return setTagToHistoryEvent(historyObject, tag);
  });
}

export async function getEventMappingMadMlData(tenant: number) {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/events/madMl`
    );
    const { history = [], disabledConnectors } = data;
    const { email } = history;
    const liveMadMlQuery = getLiveMadMlQueryFromHistory(history);

    const tagetHistory = setTagToHistoryEventsList(
      history,
      EventsTags.Published
    );

    return {
      email,
      madMlSqlQuery: liveMadMlQuery,
      history: tagetHistory,
      disabledConnectors,
    };
  } catch (e) {
    return {
      email: null,
      madMlSqlQuery: '',
      history: [],
      disabledConnectors: [],
    };
  }
}

export async function getEvents(
  tenant: number,
  connector: string,
  filteredByEventGroup = true
): Promise<UnknownObject> {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/events/${connector}`
    );
    const { history = [] } = data;
    const { email } = history;
    const liveMadMlQuery = getLiveMadMlQueryFromHistory(history);
    const { disabledConnectors = [] } = data;

    let liveEvents = getLiveEventsFromHistory(history);
    if (filteredByEventGroup && liveEvents) {
      liveEvents = filterEventsByEventGroup(connector, liveEvents);
    }

    const tagetHistory = setTagToHistoryEventsList(
      history,
      EventsTags.Published
    );
    return {
      events: liveEvents,
      email,
      disabledConnectors,
      history: tagetHistory,
      madMlSqlQuery: liveMadMlQuery,
    };
  } catch (e) {
    return {
      events: [],
      email: null,
      disabledConnectors: [],
      history: [],
      madMlSqlQuery: '',
    };
  }
}

export function getEventsOfConnector(
  events: UnknownObject,
  connector: EventMappingConnectorsNames
) {
  return events
    ? events.filter((event: UnknownObject) => {
        const { eventGroup } = event;
        if (
          connector === CONNECTORS_NAMES.hubspot &&
          (eventGroup === EVENTS_GROUPS_NAMES.formSubmission ||
            eventGroup === EVENTS_GROUPS_NAMES.emailActivity)
        ) {
          return true;
        }
        return eventGroup === EVENTS_GROUPS_NAMES[connector];
      })
    : [];
}

export function extractAllDisabledEventsArray(
  allLiveConfigurations: UnknownArrayOfObjects,
  disabledConnectors: string[]
): UnknownArrayOfObjects {
  return disabledConnectors
    .map((disabledConnectorName: string) => {
      return allLiveConfigurations
        .map((connectorAttributes: any) => {
          return getEventsOfConnector(
            connectorAttributes.events,
            disabledConnectorName as EventMappingConnectorsNames
          );
        })
        .filter(
          (arrayOfEvents: UnknownArrayOfObjects) => arrayOfEvents?.length > 0
        );
    })
    .flat(2);
}

export async function getAllLiveConfiguration(tenant: number) {
  const promisesList = Object.keys(EventMappingConnectorsNames).map(
    (connector) => {
      return getEvents(tenant, connector);
    }
  );
  const allLiveConfigurations = await Promise.all(promisesList);
  return allLiveConfigurations;
}

export function extractAllEventsFromConfigurations(
  allLiveConfigurations: UnknownArrayOfObjects
) {
  return allLiveConfigurations.reduce(
    (acc: UnknownArrayOfObjects, currentValue: any) => {
      return acc.concat(currentValue.events || []);
    },
    []
  ) as UnknownArrayOfObjects;
}

export function extractOnlyEnabledEvents(
  allEvents: UnknownArrayOfObjects,
  disabledEvents: UnknownArrayOfObjects
) {
  return allEvents.filter((event: any) => {
    return !disabledEvents.includes(event);
  });
}

export async function getAllEvents(tenant: number) {
  const allLiveConfigurations = await getAllLiveConfiguration(tenant);
  return extractAllEventsFromConfigurations(allLiveConfigurations);
}

export async function getAllEnabledLiveEventsAllConnectors(
  tenant: number,
  disabledConnectors: string[]
) {
  const allLiveConfigurations = (await getAllLiveConfiguration(tenant)) || [];
  const allEvents: UnknownArrayOfObjects =
    (await extractAllEventsFromConfigurations(allLiveConfigurations)) || [];
  const disabledEvents: UnknownArrayOfObjects =
    extractAllDisabledEventsArray(allLiveConfigurations, disabledConnectors) ||
    [];
  return extractOnlyEnabledEvents(allEvents, disabledEvents);
}

export async function extractAllLiveAndEnabledEventsExceptConnector(
  tenant: number,
  connector: string,
  enabledEvents: any = [],
  disabledConnectors: string[]
) {
  if (!enabledEvents) {
    // eslint-disable-next-line no-param-reassign
    enabledEvents =
      (await getAllEnabledLiveEventsAllConnectors(
        tenant,
        disabledConnectors
      )) || [];
  }
  return enabledEvents
    ? enabledEvents
        .filter((event: UnknownObject) => event)
        .filter((event: UnknownObject) => {
          const { eventGroup } = event;
          if (
            connector === CONNECTORS_NAMES.hubspot &&
            (eventGroup === EVENTS_GROUPS_NAMES.formSubmission ||
              eventGroup === EVENTS_GROUPS_NAMES.emailActivity)
          ) {
            return false;
          }
          return eventGroup !== EVENTS_GROUPS_NAMES[connector];
        })
    : [];
}

function getActualEventsTag(
  savedConnectorEvents: any,
  publishedConnectorEvents: any
): EventsTags {
  if (publishedConnectorEvents && publishedConnectorEvents.length > 0) {
    return EventsTags.Live;
  }
  if (savedConnectorEvents && savedConnectorEvents.length > 0) {
    return EventsTags.Draft;
  }
}

function getActualMadMlTag(
  savedMadMlSqlQuery: string,
  publishedMadMlSqlQuery: string
): EventsTags {
  if (publishedMadMlSqlQuery && publishedMadMlSqlQuery.length > 0) {
    return EventsTags.Live;
  }
  return EventsTags.Draft;
}

export function filterAndCampareEvents(options: {
  connector: string;
  email: string;
  publishedEvents: any[];
  history: any[];
  savedEventObject: any;
  disabledConnectors: string[];
}): EventProperties {
  const {
    connector,
    email,
    savedEventObject,
    publishedEvents,
    history,
    disabledConnectors,
  } = options;

  const { events: savedEvents = [], email: savedEmail } = savedEventObject;

  let draft = false;

  const publishedConnectorEvents =
    filterEventsByConnector(connector, publishedEvents) || [];

  const savedConnectorEvents =
    filterEventsByConnector(connector, savedEvents) || [];

  if (
    // if saved/publish aren't an array, don't exist, or if published events are empty,
    // make sure draft mode isn't set to true - therefore not entering this if
    publishedConnectorEvents &&
    savedConnectorEvents &&
    Array.isArray(publishedConnectorEvents) &&
    Array.isArray(savedConnectorEvents) &&
    savedConnectorEvents.length
  ) {
    draft = !areEventsSame(savedConnectorEvents, publishedConnectorEvents);
  }

  const isPublishedEventsExist =
    publishedConnectorEvents && publishedConnectorEvents.length > 0;
  const isSavedEventsExist =
    savedConnectorEvents && savedConnectorEvents.length > 0;
  if (isSavedEventsExist) {
    history.push(setTagToHistoryEvent(savedEventObject, EventsTags.Draft));
  }
  const result: EventProperties = {
    email: savedEmail || email,
    disabledConnectors,
    draft,
    history,
    savedConnectorEvents,
    publishedConnectorEvents,
    currentTag: getActualEventsTag(
      savedConnectorEvents,
      publishedConnectorEvents
    ),
    madMlSqlQuery: '',
    isItOldEvents: true,
  };
  return isPublishedEventsExist || isSavedEventsExist
    ? result
    : DEFAULT_EVENT_PROPERTIES;
}

export function filterAndCampareMadMlSqlQueries(options: {
  publishedMadMlSqlQuery: string;
  history: any[];
  savedEventObject: any;
  email: string;
  disabledConnectors: string[];
}): EventProperties {
  const {
    savedEventObject,
    email,
    history,
    publishedMadMlSqlQuery,
    disabledConnectors,
  } = options;

  const {
    email: savedEmail,
    madMlSqlQuery: savedMadMlSqlQuery,
  } = savedEventObject;
  let draft = false;

  const isSavedMadMlExists: boolean =
    savedMadMlSqlQuery && savedMadMlSqlQuery.length > 0;
  const isPublishedMadMlExists: boolean =
    publishedMadMlSqlQuery && publishedMadMlSqlQuery.length > 0;

  if (isPublishedMadMlExists && isSavedMadMlExists) {
    draft = !areMadMlQueriesSame(savedMadMlSqlQuery, publishedMadMlSqlQuery);
  }
  if (isSavedMadMlExists) {
    history.push(setTagToHistoryEvent(savedEventObject, EventsTags.Draft));
  }
  const result: EventProperties = {
    email: savedEmail || email,
    disabledConnectors,
    draft,
    history,
    savedConnectorEvents: [],
    publishedConnectorEvents: [],
    currentTag: getActualMadMlTag(savedMadMlSqlQuery, publishedMadMlSqlQuery),
    madMlSqlQuery: isPublishedMadMlExists
      ? publishedMadMlSqlQuery
      : savedMadMlSqlQuery,
    isItOldEvents: true,
  };
  return isPublishedMadMlExists || isSavedMadMlExists
    ? result
    : DEFAULT_EVENT_PROPERTIES;
}

// get saved or published events, and return if draft mode
export async function getEventsFromDb(
  tenant: number,
  connector: string
): Promise<EventProperties> {
  const isCustom = connector === ConnectorsNames.madMl;

  try {
    const {
      events: publishedEvents,
      email,
      disabledConnectors,
      history,
      madMlSqlQuery: publishedMadMlSqlQuery,
    } = await getEvents(tenant, connector); // Reference bug happened, did the clean
    const savedEventObject = await getSaved(tenant, connector);

    if (!isCustom) {
      return filterAndCampareEvents({
        connector,
        disabledConnectors,
        history,
        publishedEvents,
        email,
        savedEventObject,
      });
    }
    return filterAndCampareMadMlSqlQueries({
      publishedMadMlSqlQuery,
      savedEventObject,
      history,
      email,
      disabledConnectors,
    });
  } catch (e) {
    return DEFAULT_EVENT_PROPERTIES;
  }
}

function getMostRelevantDbEvents(
  savedConnectorEvents: any,
  publishedConnectorEvents: any
) {
  if (publishedConnectorEvents && publishedConnectorEvents.length > 0) {
    return publishedConnectorEvents;
  }
  return savedConnectorEvents;
}

export function sortRelevantEvents(
  relevantEvents: RelevantEventsInformationModel[]
): RelevantEventsInformationModel[] {
  return relevantEvents.sort(
    (a, b) =>
      EVENT_MAPPING_CONNECTORS_VALUES[b.connector] -
      EVENT_MAPPING_CONNECTORS_VALUES[a.connector]
  );
}

// get events from dynamo (will return _saved events if exists, else published events)
export async function getRelevantEvents(tenant: number, connector: string) {
  const isCustom: boolean = connector === ConnectorsNames.madMl;

  const result: EventProperties = await getEventsFromDb(tenant, connector);
  const {
    savedConnectorEvents,
    publishedConnectorEvents,
    isItOldEvents,
    email,
    disabledConnectors,
    draft,
    history,
    currentTag,
    madMlSqlQuery,
  } = result;
  let events;

  const mostRelevantEvents = isCustom
    ? []
    : getMostRelevantDbEvents(savedConnectorEvents, publishedConnectorEvents);

  if (Array.isArray(mostRelevantEvents) && mostRelevantEvents.length > 0) {
    if (isItOldEvents && isItOldEvents === true) {
      events = mostRelevantEvents;
    }
  } else {
    events = [];
  }

  events = events.map((event) => ({
    ...event,
    conditionFormData: {
      ...event.conditionFormData,
      // Set up 'conditions' array if not exists.
      conditions: Array.isArray(event.conditionFormData?.conditions)
        ? event.conditionFormData.conditions
        : [],
    },
  }));

  return {
    events,
    email,
    disabledConnectors,
    draft,
    history,
    currentTag,
    connector,
    madMlSqlQuery,
  };
}

export function transformHistoryListToSelectOptions(
  historyNamesList: string[]
): SelectOptionDataModel[] {
  return historyNamesList?.map((historyName: string, index) => {
    const selectOption = new SelectOptionDataModel();
    selectOption.value = index;
    selectOption.label = historyName;
    return selectOption;
  });
}

export function transformHistoryToHistoryNamesList(
  history: UnknownArrayOfObjects
): string[] {
  return history?.map((historyObject) => {
    if (historyObject?.tag === EventsTags.Draft) {
      return EventsTags.Draft;
    }
    const date = new Date(historyObject.updated_at).toISOString();
    if (historyObject?.live) {
      return `${EventsTags.Live} ${date}`;
    }
    return `${EventsTags.Published} ${date}`;
  });
}

export function checkAndSetActiveConnectors(
  eventsSqlChunk: UnknownObject = {},
  activeConnectors: string[]
) {
  const newEventsSqlChunk = eventsSqlChunk || {};
  Object.keys(CONNECTORS_NAMES).forEach((connector) => {
    const hasConnector = CONNECTORS_TO_YAML[connector];
    // eslint-disable-next-line no-param-reassign
    newEventsSqlChunk[hasConnector] = activeConnectors.includes(connector);
  });
  return newEventsSqlChunk;
}

export async function save(
  tenant: number,
  email: string,
  events: UnknownArrayOfObjects,
  connector: string
) {
  return axios.put(
    `${BONGO_URL}/v1/org/${tenant}/data/mappings/events/${connector}/saved`,
    {
      connector,
      email,
      events,
    }
  );
}

/**
 * the getFormData returns a promise, each formData must be resolved, this is why there is the Promise.all
 * @param connector
 * @param most_frequent_events
 * @returns {Promise<EventObject>}
 */
export async function fillArrayOfFormData(
  connector: string,
  most_frequent_events: UnknownArrayOfObjects = []
) {
  return Promise.all(
    most_frequent_events.map((element) => {
      const formDataObject: UnknownObject = {
        event: element.event,
        nonUserActivity: element.nonUserActivity !== false,
        mkEventName:
          element.mkEventName || convertToMkEventStyle(element.event),
        mkEventNameSignals:
          element.mkEventNameSignals || convertToMkEventStyle(element.event),
        signals: element.signals || false,
        activityType: element.activityType || ACTIVITY_TYPE[0],
        eventGroup: EVENTS_GROUPS_NAMES[connector],
      };
      if (element.conditionFormData) {
        formDataObject.conditionFormData = element.conditionFormData;
      }
      // eslint-disable-next-line no-return-await
      return getFormData(connector, formDataObject);
    })
  );
}

export function convertDataToListOfActiveIntegrationModels(
  data: any
): ActiveIntegrationDataModel[] {
  const arrayOfNames: string[] = [];
  return data.map((element: any) => {
    if (arrayOfNames.includes(element.name)) {
      return;
    }
    const activeIntegration = new ActiveIntegrationDataModel(element.name);
    arrayOfNames.push(element.name);
    return activeIntegration;
  });
}

export function cleanArrayFromNullOrUndefinedValues(array: any[]) {
  return array.filter((e) => e);
}

function removeSalesforceIntegrationFromArray(
  activeIntegrations: ActiveIntegrationDataModel[]
) {
  const newActiveIntegrations = activeIntegrations;
  // Search for the index of the "salesforce", Can't use the classic function array.indexOf because we manipulate classes
  const indexOfSalesforceActiveIntegration: number = newActiveIntegrations.findIndex(
    (activeIntegration) => {
      return activeIntegration.name === ConnectorsNames.salesforce;
    }
  );
  // Remove the "salesforce" integration, which is now replaced by 'salesforce_tasks' and 'salesforce_campaigns'
  newActiveIntegrations.splice(indexOfSalesforceActiveIntegration, 1);
  return newActiveIntegrations;
}

export function checkForSalesforceAndSplitToTwoSubConnectorsIfExist(
  activeIntegrationsArray: ActiveIntegrationDataModel[]
): ActiveIntegrationDataModel[] {
  const newActiveIntegrationsArray: ActiveIntegrationDataModel[] = activeIntegrationsArray; // Create a copy from the prop var

  const isSalesforceExist: boolean = !!activeIntegrationsArray.filter(
    (activeIntegration) => {
      return activeIntegration.name === ConnectorsNames.salesforce;
    }
  )[0]; // Search for salesforce connector

  // if salesforce Exist
  if (isSalesforceExist) {
    // Add "salesforce_campaigns" to the list
    newActiveIntegrationsArray.push(
      new ActiveIntegrationDataModel(
        EventMappingConnectorsNames.salesforce_campaigns
      )
    );

    // Add "salesforce_tasks" to the list
    newActiveIntegrationsArray.push(
      new ActiveIntegrationDataModel(
        EventMappingConnectorsNames.salesforce_tasks
      )
    );

    return removeSalesforceIntegrationFromArray(newActiveIntegrationsArray);
  }
  return newActiveIntegrationsArray; // Return the new object
}

export function extractOnlyEventMappingKnownConnectors(
  activeIntegrationsWithSubConnectors: ActiveIntegrationDataModel[]
) {
  return activeIntegrationsWithSubConnectors.filter(
    (activeIntegration: ActiveIntegrationDataModel) => {
      return Object.keys(EventMappingConnectorsNames).includes(
        activeIntegration.name
      );
    }
  );
}

export function addMadMlIntegrationToActiveIntegrations(
  activeIntegrations: ActiveIntegrationDataModel[]
): ActiveIntegrationDataModel[] {
  const newActiveIntegrations = activeIntegrations;
  newActiveIntegrations.push(
    new ActiveIntegrationDataModel(EventMappingConnectorsNames.madMl)
  );
  return newActiveIntegrations;
}

export function filterActiveIntegration(
  arrayOfActiveIntegrations: ActiveIntegrationDataModel[]
) {
  const arrayWithUniqueActiveIntegrations = cleanArrayFromNullOrUndefinedValues(
    arrayOfActiveIntegrations
  );

  const activeIntegrationsWithSubConnectors = checkForSalesforceAndSplitToTwoSubConnectorsIfExist(
    arrayWithUniqueActiveIntegrations
  );

  const activeIntegrationsWithKnownConnectors = extractOnlyEventMappingKnownConnectors(
    activeIntegrationsWithSubConnectors
  );

  return addMadMlIntegrationToActiveIntegrations(
    activeIntegrationsWithKnownConnectors
  );
}
