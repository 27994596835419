import React, { Fragment } from 'react';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnowflakeContext } from '../contexts/SnowflakeContext';
import { Loader } from '../../../components/Loader';
import {
  Columns,
  getColumnsFromTable,
  getUsedColumnsInTable,
  getTableLabel,
  MAPPABLE_TABLE,
  MAPPABLE_TABLES,
  REQUIRED_COLUMN_VALUES,
  REQUIRED_COLUMNS,
} from '../../utils/data-warehouse';

type Props = {
  previousStep: () => void;
  nextStep: () => void;
};

const SNOWFLAKE_COLUMN_DESCRIPTIONS: Record<
  MAPPABLE_TABLE,
  Partial<Record<Columns<MAPPABLE_TABLE>, string>>
> = {
  event: {},
  contact_update: {
    account_id:
      "By default, MadKudu will use the Snowflake contact email. You can provide a column under 'account_id' that contains the Snowflake account ID to match Snowflake contacts and accounts explicitly.",
  },
  account_update: {
    salesforce_account_id:
      "By default, MadKudu will use the domain to match Snowflake accounts with Salesforce accounts. You can provide a column under 'salesforce_account_id' that contains the Salesforce account ID to match Snowflake and Salesforce accounts explicitly.",
  },
};

export function ColumnMappingStep({ previousStep }: Props) {
  const {
    tenant,
    setEditMode,
    getColumns,
    credentials,
    setCredentials,
    save,
  } = useSnowflakeContext();

  const columnsCacheKey = [
    'snowflake',
    'columns',
    tenant,
    credentials?.account ?? '',
    credentials?.username ?? '',
    credentials?.pwd ?? '',
    credentials?.database ?? '',
    credentials?.schema ?? '',
    credentials?.tableNames?.event ?? '',
    credentials?.tableNames?.contact_update ?? '',
    credentials?.tableNames?.account_update ?? '',
  ];
  const { data: columns, isLoading: loadingColumns } = useQuery(
    columnsCacheKey,
    getColumns
  );

  const { mutate, isLoading: isSaving } = useMutation(save);

  const { tableNames, columnNames = REQUIRED_COLUMN_VALUES } =
    credentials ?? {};

  const validate = (): boolean => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [ourTable, theirTable] of Object.entries(tableNames)) {
      // eslint-disable-next-line no-continue
      if (!theirTable) continue;
      const requiredColumns =
        REQUIRED_COLUMNS[ourTable as keyof typeof REQUIRED_COLUMNS];
      // eslint-disable-next-line no-restricted-syntax
      for (const column of requiredColumns) {
        // @ts-ignore - we know this is valid
        if (!columnNames?.[ourTable]?.[column]) return false;
      }
    }
    return true;
  };

  const handleOnChange = (table: MAPPABLE_TABLE, column: string) => (option: {
    label: string;
    value: string;
  }) => {
    const isRequired = Object.hasOwn(
      REQUIRED_COLUMNS[table as MAPPABLE_TABLE],
      column as keyof typeof REQUIRED_COLUMNS[typeof table]
    );
    setCredentials({
      ...credentials,
      columnNames: {
        ...(credentials?.columnNames ?? {}),
        [table]: {
          ...(credentials?.columnNames?.[table] ?? {}),
          [column]: option?.value ?? (isRequired ? '' : undefined),
        },
      },
    });
  };

  const getUsableColumnOptions = (table: MAPPABLE_TABLE) => {
    const usedColumns = getUsedColumnsInTable(table, columnNames);
    return (
      columns?.[table]?.map?.((col) => ({
        label: col,
        value: col,
        isDisabled: usedColumns.includes(col),
      })) ?? []
    );
  };

  const handleSaveClick = async () => {
    const valid = validate();

    if (!valid) {
      Swal.fire({
        title: 'Invalid column mapping',
        text: 'Please map all columns before saving',
        icon: 'error',
      });

      return;
    }

    mutate(null, {
      onSuccess: (saved) => {
        if (saved) {
          Swal.fire({
            title: 'Your Snowflake integration is set up!',
            html:
              'MadKudu will start pulling moving forward. To pull historical data please click <a href="https://madkudusupport.zendesk.com/hc/en-us/requests?new" target="_blank">here</a>',
            icon: 'success',
          });
          setEditMode(false);
        } else {
          Swal.fire({
            title: 'Invalid column mapping',
            text:
              'MadKudu is unable to find these columns. Please verify they exist in your Snowflake database.',
            icon: 'error',
          });
        }
      },
    });
  };

  const mappedTables = MAPPABLE_TABLES.filter((table) => tableNames?.[table]);

  return (
    <>
      {loadingColumns && <Loader className="m-auto w-50" />}
      {loadingColumns || (
        <>
          <div className="row justify-content-center">
            <div className="col-8">
              <div className="card box-shadow-3d mb-3">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src="/media/integrations/logos/snowflake-icon.png"
                    />
                    <h5 className="mb-0 ml-3">Snowflake</h5>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="mt-3">Column mapping</h5>
                  <p>
                    Configure how columns from Snowflake should be mapped to the
                    required fields in MadKudu. The rest of your columns will be
                    synced to MadKudu as they are named.
                  </p>

                  <form>
                    <div className="m-3">
                      {mappedTables.map((table) => {
                        const clientTableName = tableNames[table];
                        const allColumns = getColumnsFromTable(table);
                        return (
                          <div className="card mb-2" key={table}>
                            <div className="card-header">
                              {getTableLabel(table)} ({clientTableName})
                            </div>
                            <div className="card-body">
                              {allColumns.map(({ column, required }) => {
                                const value =
                                  columnNames?.[table]?.[
                                    column as keyof typeof columnNames[typeof table]
                                  ] ?? '';
                                const valueOption = value
                                  ? { label: value, value }
                                  : null;
                                const description =
                                  SNOWFLAKE_COLUMN_DESCRIPTIONS[table]?.[
                                    column as keyof typeof SNOWFLAKE_COLUMN_DESCRIPTIONS[typeof table]
                                  ];
                                return (
                                  <Fragment key={`${table}-${column}`}>
                                    <div className="form-group row">
                                      <label className="col-sm-3 col-form-label">
                                        {column}&nbsp;
                                        {required && (
                                          <sup className="text-danger">*</sup>
                                        )}
                                      </label>
                                      <div className="col-sm-9">
                                        <Select
                                          key={column}
                                          value={valueOption}
                                          placeholder={`Select a column (${column})`}
                                          onChange={handleOnChange(
                                            table,
                                            column
                                          )}
                                          options={getUsableColumnOptions(
                                            table
                                          )}
                                          isClearable
                                          isDisabled={!columns?.[table]?.length}
                                        />
                                      </div>
                                    </div>
                                    {description && (
                                      <div className="mb-2">
                                        <p className="text-muted">
                                          {description}
                                        </p>
                                      </div>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 pb-4 text-center">
            <button
              className="btn btn-light mr-2"
              disabled={isSaving}
              onClick={previousStep}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              disabled={isSaving}
              onClick={handleSaveClick}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </>
      )}
    </>
  );
}
