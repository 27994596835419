var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (credentialsFound, domain, domain_placeholder, secret_key, secret_key_placeholder) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E";
if (credentialsFound) {
pug_html = pug_html + "\u003Cp\u003E\u003Cb\u003ESuccessfully connected to Freshsales.\u003C\u002Fb\u003E\u003C\u002Fp\u003E\u003Cdiv class=\"row mb-2\"\u003E\u003Cp class=\"col-3\"\u003Ecan update your credentials below or\u003C\u002Fp\u003E\u003Cbutton class=\"btn btn-danger col-2\" id=\"mki_delete_connection\"\u003EDelete connection\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Csmall\u003ECheck out this&nbsp;\u003Ca href=\"https:\u002F\u002Fhelp.madkudu.com\u002Fdocs\u002Fintegrations-overview\" target=\"_blank\"\u003Ehelp page\u003C\u002Fa\u003E&nbsp;if you're not sure about where to find this information.\u003C\u002Fsmall\u003E\u003Cform class=\"form-horizontal m-t-md m-b-md\" id=\"form_freshsales\"\u003E\u003Cdiv class=\"row form-group\"\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Clabel for=\"domain\"\u003EFreshsales Domain\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-6\"\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"mki_input_domain\" type=\"text\" name=\"domain\""+pug.attr("placeholder", domain_placeholder, true, true)+pug.attr("value", domain, true, true)+" autocomplete=\"off\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row form-group\"\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Clabel for=\"username\"\u003EFreshsales API Key\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-6\"\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"mki_input_secret_key\" type=\"text\" name=\"secret_key\""+pug.attr("placeholder", secret_key_placeholder, true, true)+pug.attr("value", secret_key, true, true)+" autocomplete=\"off\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cbutton class=\"btn btn-success mr-2\" id=\"btn_save\"\u003ESave\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-light btn-save\" id=\"btn_cancel\"\u003ECancel\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003Cdiv id=\"fields_mapping\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "credentialsFound" in locals_for_with ?
        locals_for_with.credentialsFound :
        typeof credentialsFound !== 'undefined' ? credentialsFound : undefined, "domain" in locals_for_with ?
        locals_for_with.domain :
        typeof domain !== 'undefined' ? domain : undefined, "domain_placeholder" in locals_for_with ?
        locals_for_with.domain_placeholder :
        typeof domain_placeholder !== 'undefined' ? domain_placeholder : undefined, "secret_key" in locals_for_with ?
        locals_for_with.secret_key :
        typeof secret_key !== 'undefined' ? secret_key : undefined, "secret_key_placeholder" in locals_for_with ?
        locals_for_with.secret_key_placeholder :
        typeof secret_key_placeholder !== 'undefined' ? secret_key_placeholder : undefined));
    ;;return pug_html;};
module.exports = template;