import React from 'react';
import { ConnectorFieldNameDataModel } from '../../mapping/models/ConnectorFieldNameDataModel';
import OverviewSectionComponent from './OverviewSectionComponent';
import PullSectionComponent from './PullSectionComponent';
import PushSectionComponent from './PushSectionComponent';

type IntegrationParentComponentProps = {
  getActivePage: Function;
  toggleUrl: Function;
  integration: string;
  pullFields: ConnectorFieldNameDataModel[];
  pushConfigData: ConnectorFieldNameDataModel[];
  preRequisites: string;
  overviewTextSection: string;
  pullTextSection: string;
  pushTextSection: string;
  tenant: number;
  credentialsFound: boolean;
  isPullActive: boolean;
  isPushActive: boolean;
  writeKey: string;
};

type IntegrationParentComponentState = {
  activeLink: string;
};

export default class IntegrationParentComponent extends React.Component<
  IntegrationParentComponentProps,
  IntegrationParentComponentState
> {
  constructor(props: IntegrationParentComponentProps) {
    super(props);
    const page = props.getActivePage();
    this.state = {
      activeLink: page.charAt(0).toUpperCase() + page.slice(1),
    };
    this.handleNavLinkChange = this.handleNavLinkChange.bind(this);
    this.handleClickOnActivatePull = this.handleClickOnActivatePull.bind(this);
    this.handleClickOnActivatePush = this.handleClickOnActivatePush.bind(this);
    this.renderPullButton = this.renderPullButton.bind(this);
    this.renderPushButton = this.renderPushButton.bind(this);
    this.renderOverviewDescriptionHeader = this.renderOverviewDescriptionHeader.bind(
      this
    );
  }

  componentDidMount() {
    const { activeLink } = this.state;
    const { toggleUrl } = this.props;
    toggleUrl(activeLink);
  }

  handleNavLinkChange(text: string) {
    const { toggleUrl } = this.props;
    this.setState({
      activeLink: text,
    });
    const newUrl = toggleUrl(text);
    return newUrl;
  }

  getActiveLinkElement(text: string) {
    return (
      <a
        className="nav-link text-decoration-none active"
        onClick={() => this.handleNavLinkChange(text)}
      >
        {text}
      </a>
    );
  }

  getInactiveLinkElement(text: string) {
    return (
      <a
        className="nav-link text-reset text-decoration-none"
        onClick={() => this.handleNavLinkChange(text)}
      >
        {text}
      </a>
    );
  }

  renderLink(text: string) {
    const { activeLink } = this.state;
    if (text === activeLink) {
      return this.getActiveLinkElement(text);
    }
    return this.getInactiveLinkElement(text);
  }

  handleClickOnActivatePull() {
    const { activeLink } = this.state;

    if (activeLink === 'Overview') {
      return () => {
        this.handleNavLinkChange('Pull');
      };
    }
    if (activeLink === 'Pull') {
      return () => this.redirectToOauth();
    }
  }

  handleClickOnActivatePush() {
    return () => this.handleNavLinkChange('Push');
  }

  redirectToOauth() {
    const { tenant, integration } = this.props;
    window.location.href = `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/oauth/redirect`;
  }

  renderSection() {
    const {
      getActivePage,
      integration,
      preRequisites,
      overviewTextSection,
      pullTextSection,
      pushTextSection,
      tenant,
      credentialsFound,
      pullFields,
      pushConfigData,
      writeKey,
    } = this.props;
    switch (getActivePage()) {
      case 'overview':
        return (
          <OverviewSectionComponent
            preRequisites={preRequisites}
            overviewTextSection={overviewTextSection}
            pullTextSection={pullTextSection}
            pushTextSection={pushTextSection}
            integration={integration}
            renderPullButton={() => this.renderPullButton()}
            renderPushButton={() => this.renderPushButton()}
            renderOverviewDescriptionHeader={() =>
              this.renderOverviewDescriptionHeader()
            }
          />
        );
      case 'pull':
        return (
          <PullSectionComponent
            pullFields={pullFields}
            tenant={tenant}
            integration={integration}
            credentialsFound={credentialsFound}
            renderPullButton={() => this.renderPullButton()}
          />
        );
      case 'push':
        return (
          <PushSectionComponent
            writeKey={writeKey}
            pushConfigData={pushConfigData}
            tenant={tenant}
            integration={integration}
            credentialsFound={credentialsFound}
          />
        );
      default:
        return (
          <OverviewSectionComponent
            preRequisites={preRequisites}
            overviewTextSection={overviewTextSection}
            pullTextSection={pullTextSection}
            pushTextSection={pushTextSection}
            integration={integration}
            renderPullButton={() => this.renderPullButton()}
            renderPushButton={() => this.renderPushButton()}
            renderOverviewDescriptionHeader={() =>
              this.renderOverviewDescriptionHeader()
            }
          />
        );
    }
  }

  getPullingOrPushingDataFromIntegrationGuideCard(action: string) {
    const { integration } = this.props;

    return (
      <div className="row justify-content-center">
        <div className="card box-shadow-3d d-flex h-100 w-75 border-primary bg-primary-fade text-primary">
          <div className="card-body">
            <h5 className="mt-2">
              MadKudu is now {action} data from{' '}
              <span className="text-capitalize">{integration}!</span>
            </h5>
          </div>
        </div>
      </div>
    );
  }

  renderOverviewDescriptionHeader() {
    const { writeKey, credentialsFound, integration } = this.props;

    if (writeKey && credentialsFound) {
      return (
        <div className="ml-1 my-auto d-flex justify-content-between flex-row float-right">
          <div className="success-div rounded-circle mr-1"></div>
          <span>
            Pulling/Pushing data from{' '}
            <span className="text-capitalize"> {integration}</span>
          </span>
        </div>
      );
    }
    if (writeKey && !credentialsFound) {
      return (
        <div className="ml-1 my-auto d-flex justify-content-between flex-row float-right">
          <div className="success-div rounded-circle mr-1"></div>
          <span>
            Pushing data to{' '}
            <span className="text-capitalize"> {integration}</span>
          </span>
        </div>
      );
    }
    if (credentialsFound && !writeKey) {
      return (
        <div className="ml-1 my-auto d-flex justify-content-between flex-row float-right">
          <div className="success-div rounded-circle mr-1"></div>
          <span>
            Pulling data from{' '}
            <span className="text-capitalize"> {integration}</span>
          </span>
        </div>
      );
    }
  }

  renderAppropriateGuideCard() {
    const { activeLink } = this.state;
    const { credentialsFound, isPushActive, writeKey, pullFields } = this.props;

    switch (activeLink) {
      case 'Pull':
        if (credentialsFound && !pullFields)
          return this.getPullingOrPushingDataFromIntegrationGuideCard(
            'pulling'
          );
        break;
      case 'Push':
        if (writeKey && !isPushActive)
          return this.getPullingOrPushingDataFromIntegrationGuideCard(
            'pushing'
          );
        break;
      default:
    }
    return <div className="row w-100"></div>;
  }

  renderPullButton() {
    const { credentialsFound } = this.props;
    if (credentialsFound) {
      return (
        <button type="button" className="btn btn-success mr-2">
          Pull is Active
        </button>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-outline-primary mr-2"
        onClick={this.handleClickOnActivatePull()}
      >
        Activate Pull
      </button>
    );
  }

  renderPushButton() {
    const { writeKey } = this.props;
    if (writeKey) {
      return (
        <button type="button" className="btn btn-success w-50">
          Push is Active
        </button>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-outline-primary w-50"
        onClick={this.handleClickOnActivatePush()}
      >
        Activate Push
      </button>
    );
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d">
          <ul className="nav nav-pills m-auto">
            <li className="nav-item" key="Overview">
              {this.renderLink('Overview')}
            </li>
            <li className="nav-item" key="Pull">
              {this.renderLink('Pull')}
            </li>
            <li className="nav-item" key="Push">
              {this.renderLink('Push')}
            </li>
          </ul>
        </nav>
        <div className="container mt-4 pb-5 w-50">
          {this.renderAppropriateGuideCard()}
          {this.renderSection()}
        </div>
      </div>
    );
  }
}
