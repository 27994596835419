import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import EventMappingConnectorsNames from '../../../../enums/EventMappingConnectorsNames';
import EventsTags from '../../../../enums/EventsTags';
import { ANALYTICS_CONNECTORS, CONNECTORS_NAMES } from '../../../constants';
import { ConditionFormData, Event } from '../../../models/types';
import { getRelevantEvents } from '../../../utils';
import {
  getEventsValuesFieldsByConnector,
  getPrePopulatedEvents,
} from '../../generic/utils';
import useDraft from './primitives/useDraft';
import useStateReducer from './primitives/useStateReducer';

import { publishConnectorsStates } from '../../../swal';
import useAsyncEffect from './primitives/useAsyncEffect';

type EventMappingState = {
  isConnectorActive: boolean;
  history: UnknownArrayOfObjects;
  currentTag: string;
  selectedHistoryId: number;
  isComputingEvents: boolean;
  disabledConnectors: string[];
};

/**
 * Generate new event ID if needed.
 * Some legacy events were saved without IDs / with duplicate IDs in the past.
 */
function indexify<T extends object = {}>(
  array: Array<T & { id?: number }>
): Array<T & { id: number }> {
  type TrackedItem = T & { id: number };
  const ids = array.map((el) => el?.id);
  const hasDuplicateIds = new Set(ids).size !== ids.length;
  const cleanArray = !hasDuplicateIds
    ? array
    : array.map((item) => {
        const _item = cloneDeep(item);
        delete _item.id; // Reset if any duplicate ID was found.
        return _item;
      });
  return cleanArray.map((item) => {
    const _item = item as TrackedItem;
    if (!_item?.id || !isNumber(_item?.id)) {
      const newList = [...cleanArray] as TrackedItem[];
      // Generate ID.
      _item.id = Math.max(...newList.map((e) => e?.id ?? 0)) + 1;
    }
    return _item;
  });
}

const formatHistoryLabel = (historyItem: UnknownObject) => {
  if (historyItem?.tag === EventsTags.Draft) {
    return EventsTags.Draft;
  }
  const date = moment(historyItem?.updatedAt ?? 0).format('lll');
  return historyItem?.live
    ? EventsTags.Live
    : `${EventsTags.Published} ${date}`;
};

export type IndexedEvent = Partial<Event> & { originalIndex: number };

function useEventMappingHistory(tenant: number, connector: string) {
  const {
    originalState: originalEvents,
    setOriginalState: setOriginalEvents,
    currentState: events,
    setCurrentState: setEvents,
    hasUnsavedChanges,
    reset: resetCurrentEvents,
  } = useDraft<IndexedEvent[]>([]);

  const {
    originalState: originalMadMlSqlQuery,
    currentState: madMlSqlQuery,
    setOriginalState: setOriginalMadMlSqlQuery,
    setCurrentState: setMadMlSqlQuery,
    hasUnsavedChanges: hasUnsavedMadMlChanges,
    reset: resetMadMlQuery,
  } = useDraft<string>('');

  const [state, setState] = useStateReducer<EventMappingState>({
    isConnectorActive: false,
    history: [],
    currentTag: EventsTags.Live,
    selectedHistoryId: 0,
    isComputingEvents: true,
    disabledConnectors: [],
  });

  const { data: eventsData, refetch, isFetching: isLoadingEvents } = useQuery(
    [`events-${tenant}-${connector}`],
    async () => getRelevantEvents(tenant, connector),
    {
      // When the user switches between (event mapping) tabs,
      // we want to force a refresh.
      staleTime: 0,
    }
  );

  const {
    data: eventFieldValues,
    isFetching: isLoadingEventsFieldValues,
    refetch: refetchEventValues,
  } = useQuery([`events-fields-${tenant}-${connector}`], async () =>
    getEventsValuesFieldsByConnector(connector, tenant)
  );

  const isCustom: boolean = connector === EventMappingConnectorsNames.madMl;
  const isAnalytics = ANALYTICS_CONNECTORS.includes(connector);
  const isSalesforceCampaigns =
    connector === CONNECTORS_NAMES.salesforce_campaigns;
  const isSalesforceTasks = connector === CONNECTORS_NAMES.salesforce_tasks;

  const { currentTag, history, selectedHistoryId } = state;

  useAsyncEffect(async () => {
    if (eventsData) {
      const {
        events: dbEvents,
        madMlSqlQuery: _originalMadMlSqlQuery,
        disabledConnectors,
        history: dbHistory,
      } = eventsData;

      let frequentDiscoveryEvents: Event[];
      const shouldFallbackToDiscovery =
        !dbEvents.length && connector !== 'madMl';
      if (shouldFallbackToDiscovery) {
        frequentDiscoveryEvents = await getPrePopulatedEvents(
          tenant,
          connector
        );
      }
      const _events = dbEvents.length ? dbEvents : frequentDiscoveryEvents;

      const _history = indexify(dbHistory).map((historyItem) => {
        if (connector === 'madMl') {
          return historyItem;
        }
        const hasEmptyDraft =
          historyItem?.tag === 'Draft' && !historyItem?.events?.length;
        const itemEvents = (indexify(
          shouldFallbackToDiscovery && hasEmptyDraft
            ? frequentDiscoveryEvents
            : historyItem?.events ?? []
        ) as IndexedEvent[]).map((event) => {
          const _event = { ...event };
          // eslint-disable-next-line no-prototype-builtins
          if (_event?.hasOwnProperty('signals')) {
            // @ts-ignore : Deprecated key
            delete _event.signals; // Deprecate legacy event boolean 'signals' field
          }
          return _event;
        });
        return {
          ...historyItem,
          // Some legacy mappings have snake-case "updated_at" instead.
          updatedAt: historyItem?.updatedAt ?? historyItem?.updated_at,
          events: itemEvents,
        };
      });

      // Business-wise, the first loaded configuration should be the live one.
      // Otherwise, load the draft.
      const defaultSelectedHistory =
        _history.find((el) => !!el.live) ??
        _history.find((el) => el?.tag === EventsTags.Draft);
      const defaultSelectedHistoryId = defaultSelectedHistory?.id ?? 0;

      setOriginalMadMlSqlQuery(_originalMadMlSqlQuery);
      setMadMlSqlQuery(_originalMadMlSqlQuery);
      setOriginalEvents(_events);
      setEvents(_events);
      setState({
        history: _history,
        currentTag: defaultSelectedHistory?.tag || EventsTags.Draft,
        selectedHistoryId: defaultSelectedHistoryId,
        isConnectorActive:
          (isCustom ? !!_originalMadMlSqlQuery.length : !!dbEvents.length) &&
          !disabledConnectors.includes(connector),
        isComputingEvents: false,
        disabledConnectors,
      });
    }
  }, [eventsData]);

  useEffect(() => {
    if (history.length) {
      const selectedHistory = history.find(
        ({ id }) => id === selectedHistoryId
      );
      const selectedHistoryEvents = selectedHistory?.events ?? [];
      setOriginalMadMlSqlQuery(selectedHistory?.madMlSqlQuery ?? '');
      setMadMlSqlQuery(selectedHistory?.madMlSqlQuery ?? '');
      setOriginalEvents(selectedHistoryEvents);
      setEvents(selectedHistoryEvents);
      setState({
        currentTag: selectedHistory?.tag || EventsTags.Draft,
      });
      // Let enough time to the UI to update / re-render displayed items to avoid blinking effect
      setTimeout(
        () =>
          setState({
            isComputingEvents: false,
          }),
        500
      );
    }
  }, [selectedHistoryId, history]);

  const historyOptions = history.map((historyItem) => ({
    label: formatHistoryLabel(historyItem),
    value: historyItem.id,
  }));

  const selectedHistory = history.find(({ id }) => id === selectedHistoryId);

  const hasDraft = history.some((el) => el?.tag === EventsTags.Draft);

  return {
    ...state,
    originalEvents,
    setOriginalEvents,
    events,
    isUnsavedEvent: (event: IndexedEvent) => {
      const unindexedEvent = { ...event };
      delete unindexedEvent.originalIndex;
      const relatedEvent = originalEvents.find((el) => el.id === event.id);
      if (relatedEvent) delete relatedEvent.originalIndex;
      return !isEqual(unindexedEvent, relatedEvent);
    },
    searchEvents: (searchQuery: string) => {
      if (searchQuery.length) {
        const indexes = events.reduce<Record<number, string>>(
          (pairs, item, index) => {
            const pairsClone = { ...pairs };
            pairsClone[
              index
            ] = `${item?.activityType} ${item?.event} ${item?.mkEventName} ${item?.mkEventNameSignals}`;
            return pairsClone;
          },
          {}
        );
        return Object.entries(indexes).reduce<any[]>(
          (results, [eventIndex, eventRef]) => {
            const isMatched = eventRef
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
            return isMatched
              ? [
                  ...results,
                  {
                    ...events[Number(eventIndex)],
                    originalIndex: Number(eventIndex),
                  },
                ]
              : results;
          },
          []
        );
      }
    },
    setEvents,
    saveDraft: () => {
      const updatedHistory = hasDraft
        ? history.map((el) =>
            el?.tag === EventsTags.Draft ? { ...el, events, madMlSqlQuery } : el
          )
        : [
            ...history,
            {
              tag: EventsTags.Draft,
              events,
              madMlSqlQuery,
            },
          ];
      setState({
        history: updatedHistory,
        isComputingEvents: true,
        selectedHistoryId: updatedHistory.find(
          (el) => el?.tag === EventsTags.Draft
        )?.id,
      });
    },
    /** Helper for mutating a specific event. */
    setEvent: (id: number, commit: Partial<IndexedEvent>) =>
      setEvents(
        events.map((event) =>
          event.id === id ? { ...event, ...commit } : event
        )
      ),
    /** Helper for mutating a specific event condition form data. */
    setEventConditions: (id: number, conditionFormData: ConditionFormData) =>
      setEvents(
        events.map((event) =>
          event.id === id ? { ...event, conditionFormData } : event
        )
      ),
    hasUnsavedChanges,
    resetCurrentEvents,
    originalMadMlSqlQuery,
    setOriginalMadMlSqlQuery,
    madMlSqlQuery,
    setMadMlSqlQuery,
    hasUnsavedMadMlChanges,
    resetMadMlQuery,
    currentTag,
    setCurrentTag: (tag: string) => setState({ currentTag: tag }),
    selectedHistoryId,
    selectedHistory,
    setHistoryId: (id: number) => {
      setState({
        isComputingEvents: id !== selectedHistoryId,
        selectedHistoryId: id,
      });
    },
    isDraft: selectedHistory?.tag === EventsTags.Draft,
    historyOptions,
    refreshEvents: async () => {
      return Promise.all([refetch(), refetchEventValues()]);
    },
    isLoadingEvents,
    isComputingEvents: state.isComputingEvents,
    isCustom,
    isAnalytics,
    isSalesforceCampaigns,
    isSalesforceTasks,
    eventFieldValues,
    isLoadingEventsFieldValues,
    hasDraft,
    toggleConnector: async (
      disabledConnectors: string[],
      isConnectorActive: boolean
    ) => {
      await publishConnectorsStates(
        tenant,
        disabledConnectors,
        madMlSqlQuery,
        isCustom
      );
      setState({
        isConnectorActive,
        disabledConnectors,
      });
    },
  };
}

export type EventMappingHistoryManager = ReturnType<
  typeof useEventMappingHistory
>;
export default useEventMappingHistory;
