import React from 'react';

type OverviewSectionComponentProps = {
  preRequisites: string;
  overviewTextSection: string;
  pullTextSection: string;
  pushTextSection: string;
  integration: string;
  renderPullButton: () => any;
  renderPushButton: () => any;
  renderOverviewDescriptionHeader: Function;
};

export default class OverviewSectionComponent extends React.Component<
  OverviewSectionComponentProps
> {
  render() {
    const {
      preRequisites,
      overviewTextSection,
      pullTextSection,
      pushTextSection,
      integration,
      renderPullButton,
      renderPushButton,
      renderOverviewDescriptionHeader,
    } = this.props;

    return (
      <div className="row justify-content-center">
        <div className="card box-shadow-3d mt-md-2 d-flex w-75">
          <div className="card-header">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-2 justify-content-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src={`/media/integrations/logos/${`${integration}-icon`}.png`}
                    />
                  </div>
                  <div className="col-10 my-auto">
                    <h5 className="text-capitalize mb-0"> {integration}</h5>
                  </div>
                </div>
              </div>
              <div className="col-7 my-auto">
                {renderOverviewDescriptionHeader()}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-8">
                <div className="d-flex flex-column">
                  <h5>Overview</h5>
                  <div>{overviewTextSection}</div>
                </div>
                <div className="d-flex flex-column mt-3">
                  <h5>Prerequisites</h5>
                  <div>{preRequisites}</div>
                </div>
                <div className="d-flex flex-column mt-3">
                  <h5>Best Used For</h5>
                  <div className="d-flex flex-column">
                    <p>
                      <strong>Pull: </strong>
                      {pullTextSection}
                    </p>
                    <p>
                      <strong>Push: </strong>
                      {pushTextSection}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between w-75">
                  {renderPullButton()}
                  {renderPushButton()}
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <h5>Links</h5>
                  <div className="d-flex flex-column">
                    <div>
                      <a
                        href="https://help.madkudu.com/docs/segment-1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Documentation
                      </a>
                    </div>
                    <div className="mt-3">
                      <a
                        href="https://segment.com/docs/connections/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-capitalize"
                      >
                        {integration} Resources
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
