import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import model_integration from '../../models/integration';
import IntegrationParentComponent from '../components/IntegrationParentComponent';
import { isIntegrationPullActive, isIntegrationPushActive } from '../is_active';
import {
  getPullConfigForTenantAndIntegration,
  getPushConfigForTenantAndIntegration,
} from '../utils/index';

const template = require('./index.pug');

const preRequisites = `
Before authorizing MadKudu to access your Segment account, you need to be the workspace owner of it.
`;

const overviewTextSection = `
Segment is an easy way to install your favorite analytics and marketing tools (including MadKudu) on your website or mobile app.
`;
const pullTextSection = `
Send Segment data to MadKudu: your customer data in Segment (both customer attributes and customer events) are sent to MadKudu to be used for behavioral scoring. 
`;
const pushTextSection = `
Send your madkudu scores to Segment in order to apply workflow triggers to other tools: your MadKudu customer profiles are sent to Segment which can then route it to your other tools. 
`;

export default Backbone.View.extend({
  async initialize() {
    this.tenant = Number(this.model.get('_id'));
    this.integration = 'segment';
    this.credentials = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: 'segment',
    });
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });

    this.model.fetch({
      success: () => {
        this.render();
      },
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
            push_not_found: true,
          });
          this.render();
        }
      },
    });
    this.pushConfigData = await getPushConfigForTenantAndIntegration(
      this.tenant,
      this.integration
    );
    this.isPullActive = !!(await isIntegrationPullActive(
      this.tenant,
      this.integration
    ));
    this.isPushActive = !!(await isIntegrationPushActive(
      this.tenant,
      this.integration
    ));
    this.pullFields = await getPullConfigForTenantAndIntegration(
      this.tenant,
      this.integration
    );
    this.renderSegmentIntegrationParentComponent();
  },

  toggleView(tenant: string, integration: string) {
    return (page: string) => {
      Backbone.history.navigate(
        `/org/${tenant}/integrations/${integration}/${page.toLowerCase()}`
      );
    };
  },

  getActivePage() {
    const splitUrl = window.location.href.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === 'segment') {
      return 'overview';
    }
    return lastItem;
  },

  renderSegmentIntegrationParentComponent() {
    const containerDomElement = $(
      '#segmentIntegrationParentComponentContainer'
    )[0];
    if (typeof containerDomElement !== 'undefined') {
      const credentialsFound = this.model.get('not_found') !== true;
      ReactDOM.render(
        <IntegrationParentComponent
          getActivePage={this.getActivePage}
          toggleUrl={this.toggleView(this.tenant, this.integration)}
          writeKey={this.model.get('write_key')}
          pushConfigData={this.pushConfigData}
          pullFields={this.pullFields}
          integration={this.integration}
          preRequisites={preRequisites}
          overviewTextSection={overviewTextSection}
          pullTextSection={pullTextSection}
          pushTextSection={pushTextSection}
          tenant={this.tenant}
          credentialsFound={credentialsFound}
          isPullActive={this.isPullActive}
          isPushActive={this.isPushActive}
        />,
        containerDomElement
      );
    }
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
